import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

function App() {
  const [loading, setLoading] = useState(true);

  const initialHeidiServicesStatus = [
    {
      name: 'Profile',
      url: 'https://consultant.heidiservices.com/api/v1/profile/healthcheck',
    },
    {
      name: 'Consult',
      url: 'https://consultant.heidiservices.com/api/v1/consult/healthcheck',
    },
    {
      name: 'Auth',
      url: 'https://consultant.heidiservices.com/api/v1/auth/healthcheck',
    }
  ];

  const [heidiServices, setHeidiServices] = useState(initialHeidiServicesStatus);

  const initialHeidiMKGStatus = [
    {
      name: 'Chat MKG',
      url: 'https://consultant.heidiservices.com/api/v1/chat-server/healthcheck',
    },
    {
      name: 'ML MKG',
      url: 'https://consultant.heidiservices.com/api/v1/mkg/ml/healthcheck',
    },
  ];

  const [heidiMKG, setHeidiMKG] = useState(initialHeidiMKGStatus);

  const initialZaliServicesStatus = [
    {
      name: 'Profile',
      url: 'https://consultant.zali.heidiservices.com/api/v1/profile/healthcheck',
    },
    {
      name: 'Consult',
      url: 'https://consultant.zali.heidiservices.com/api/v1/consult/healthcheck',
    },
    {
      name: 'Auth',
      url: 'https://consultant.zali.heidiservices.com/api/v1/auth/healthcheck',
    }
  ];

  const [zaliServices, setZaliServices] = useState(initialZaliServicesStatus);

  const initialHeidiProjectStatus = [
    {
      name: 'Heidi Services',
    },
    {
      name: 'Zali Services',
    },
    {
      name: 'Heidi Medical AI Services',
    },
  ]

  const [heidiProjects, setHeidiProjects] = useState(initialHeidiProjectStatus);

  useEffect(() => {
    async function fetchHealthChecks() {
      try {
        for (let i = 0; i < heidiServices.length; i++) {
          const response = await axios({
            method: 'get',
            url: heidiServices[i].url,
            withCredentials: false
          });
          if (response.status === 200 || response.status === 201) {
            heidiServices[i].healthy = true;
          } else {
            heidiServices[i].healthy = false;
          }
          setHeidiServices([...heidiServices]);
        }

        for (let i = 0; i < zaliServices.length; i++) {
          const response = await axios({
            method: 'get',
            url: zaliServices[i].url,
            withCredentials: false
          });
          if (response.status === 200 || response.status === 201) {
            zaliServices[i].healthy = true;
          } else {
            zaliServices[i].healthy = false;
          }
          setZaliServices([...zaliServices]);
        }

        for (let i = 0; i < heidiMKG.length; i++) {
          const response = await axios({
            method: 'get',
            url: heidiMKG[i].url,
            withCredentials: false,
          });
          if (response.status === 200 || response.status === 201) {
            heidiMKG[i].healthy = true;
          } else {
            heidiMKG[i].healthy = false;
          }
          setHeidiMKG([...heidiMKG]);
        }

        var heidiOverallHealthy = heidiServices?.some((service) => !service.healthy) ? false : true;
        heidiProjects[0].healthy = heidiOverallHealthy

        var zaliOverallHealthy = zaliServices?.some((service) => !service.healthy) ? false : true;
        heidiProjects[1].healthy = zaliOverallHealthy

        var heidiMKGOverallHealthy = heidiMKG?.some((service) => !service.healthy) ? false : true;
        heidiProjects[2].healthy = heidiMKGOverallHealthy

        setLoading(false);
      } catch (error) {
        alert(error);
        console.error(error);
      }
    }

    
    fetchHealthChecks();
    const interval = setInterval(fetchHealthChecks, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <div className="container">
        <h1 className="heading">Heidi Health's Status</h1>
        <div className="status">
          {!loading ? (
            <>
            {heidiProjects?.map((service) => (
              <div key={service.name}>
                <h2 className="service">{service.name}</h2>
                <div>
                  <span
                    className={`indicator ${service.healthy === true ? "green" : "red"
                      }`}
                  ></span>
                  <span className="subheading">{service.healthy ? 'healthy' : 'not healthy'}</span>
                </div>
              </div>
            ))}
            {/* {heidiServices?.some((service) => !service.healthy) ? 'Heidi service over not healthy' : 'Heidi services overall healthy'} */}
            </>
          ) : (
            <div>Loading services...</div>
          )}
        </div>
        <hr />
        <div className="footer">
          Last updated: {new Date().toLocaleString()}
        </div>
      </div>
    </div>
  );
}

export default App;
